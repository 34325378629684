import iconPaperSmall from '@/components/icons/card_material/PaperIcon.vue';
import iconPlasticSmall from '@/components/icons/card_material/PlasticIcon.vue';
import iconGlassSmall from '@/components/icons/card_material/GlassIcon.vue';
import iconMetalSmall from '@/components/icons/card_material/MetalIcon.vue';

import iconPaperLarge from '@/components/icons/card_material/PaperLgIcon.vue';
import iconPlasticLarge from '@/components/icons/card_material/PlasticLgIcon.vue';
import iconGlassLarge from '@/components/icons/card_material/GlassLgIcon.vue';
import iconMetalLarge from '@/components/icons/card_material/MetalLgIcon.vue';

export default {
  methods: {
    getIconByMaterialType(type, imageLarge) {
      const imageName = `${type}_${imageLarge ? 'large' : 'small'}`;
      const icon = {
        paper_small: iconPaperSmall,
        plastic_small: iconPlasticSmall,
        glass_small: iconGlassSmall,
        metal_small: iconMetalSmall,
        paper_large: iconPaperLarge,
        plastic_large: iconPlasticLarge,
        glass_large: iconGlassLarge,
        metal_large: iconMetalLarge,
      };
      return icon[imageName];
    },
  },
};
